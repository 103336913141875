import React, { useState } from 'react'
import PoiDetailsSidebar from './PoiDetailsSidebar'
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";

const PoiContent = ({ poi, pois }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const { body, media, summary, title } = poi

    return (
        <section className='page-area ptb-50'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-8 mb-4'>
                        {media.length > 0 ?
                            <Swiper
                                spaceBetween={10}
                                thumbs={{ swiper: thumbsSwiper }}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className="mySwiper2"
                            >
                                {media.map((item, index) => {
                                    return (
                                        <SwiperSlide key={`image-swiper-${index}`}>
                                            <img src={item.original_url} alt={title} />
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                            : null
                        }
                        {media.length > 1 ?
                            <Swiper
                                onSwiper={setThumbsSwiper}
                                spaceBetween={10}
                                slidesPerView={4}
                                freeMode={true}
                                watchSlidesProgress={true}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className="mySwiper mt-2"
                            >
                                {media.map((item, index) => {
                                    return (
                                        <SwiperSlide key={`thumb-swiper-${index}`}>
                                            <img src={item.original_url} alt={title} />
                                        </SwiperSlide>
                                    )
                                })}
                            </Swiper>
                            : null
                        }
                        <div dangerouslySetInnerHTML={{ __html: summary }} className='mt-3' />
                        <div dangerouslySetInnerHTML={{ __html: body }} className='mt-3' />
                    </div>
                    <div className='col-12 col-md-4'>
                        <PoiDetailsSidebar
                            poi={poi}
                            pois={pois}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PoiContent