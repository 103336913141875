import React from 'react'
import { Link } from 'gatsby'

const ServiceCard = ({ service }) => {
    const { name, slug, summary } = service

    return (
        <Link to={`/service-details?slug=${slug}`}>
            <h6>{name}</h6>
            <small>{summary}</small>
        </Link>
    )
}

export default ServiceCard