import React from 'react'
import DocumentCard from './DocumentCard'
import ServiceCard from './ServiceCard'
import RelatedPoi from './RelatedPoi'

const PoiDetailsSidebar = ({ poi, pois }) => {
    const { documents, geo, location, services } = poi

    const city = JSON.parse(localStorage.getItem('settings')).filter(item => item.key === 'contact.address.line_2')[0].value

    return (
        <div className='contact-sidebar'>
            <div className='contact-sidebar-info'>
                <ul>
                    {location ?
                        <li>
                            <div className='icon'>
                                <i className='bx bx-map'></i>
                            </div>
                            <span>Dirección:</span>
                            {location}
                            {geo ?
                                <a href={`http://maps.google.com/maps?q=${geo.coordinates[1]},${geo.coordinates[0]}`} target='_blank' rel='noreferrer'>
                                    Como llegar
                                </a>
                                :
                                <a href={`https://www.google.es/maps/place/${location},${city}/`} target='_blank' rel='noreferrer'>
                                    Como llegar
                                </a>
                            }
                        </li>
                        : null
                    }
                    {services.length > 0 ?
                        <li>
                            <div className='icon'>
                                <i className='bx bx-cart'></i>
                            </div>
                            <span>Servicios:</span>
                            {services.map((service) => {
                                return (
                                    <ServiceCard
                                        key={`service-${service.id}`}
                                        service={service}
                                    />
                                )
                            })}
                        </li>
                        : null
                    }
                    {documents.length > 0 ?
                        <li>
                            <div className='icon'>
                                <i className='bx bx-file'></i>
                            </div>
                            <span>Adjuntos:</span>
                            {documents.map((document) => {
                                return document.media.map((media) => {
                                    return (
                                        <DocumentCard
                                            key={`media-${media.id}`}
                                            media={media}
                                        />
                                    )
                                })
                            })}
                        </li>
                        : null
                    }
                    {pois ?
                        <li>
                            <div className="icon">
                                <i className='bx bx-user-pin'></i>
                            </div>
                            <span>Relacionados:</span>
                            {pois.map((item) => (
                                <RelatedPoi
                                    key={`related-${item.id}`}
                                    poi={item}
                                />
                            ))}
                        </li>
                        : null
                    }
                </ul>
            </div>
        </div>
    )
}

export default PoiDetailsSidebar