import React from 'react'
import { Link } from 'gatsby'

const RelatedPoi = ({ poi }) => {
    const { name, slug } = poi

    return (
        <Link to={`/poi-details?slug=${slug}`}>
            {name} <br />
        </Link>
    )
}

export default RelatedPoi