import React from 'react'
import { Link } from 'gatsby'

const DocumentCard = ({ media }) => {
    const { name, original_url } = media

    return (
        <Link to={original_url} target='_blank'>
            {name}
        </Link>
    )
}

export default DocumentCard